<script>
import { mapGetters, mapActions } from 'vuex';
import { parseErrors} from "@/helpers"
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components:{

  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      errors: []
    };
  },
  validations: {
    oldPassword: { required },
    newPassword: { required }
  },
  computed :{
    ...mapGetters('account', {
        processing: 'passwordChanging'
    }),
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'statis', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  methods: {
    ...mapActions('account', {
        change: 'changePassword'
    }),
    close(){
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close");
    },
    async tryChange(){
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.errors = [];
        this.change({oldPassword: this.oldPassword, newPassword: this.newPassword }).then(()=>{
          this.$toast.open({message: 'Password was changed successfully.'});
          this.oldPassword = null;
          this.newPassword = null;
           this.close();
        }).catch(error => {
          this.oldPassword = null;
          this.newPassword = null;
          this.errors = parseErrors(error);
        });
      }
    }
  }
};
</script>

<template>
  <div
    class="modal fade"
    id="modal"
    ref="modal"
    tabindex="-1"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Change Password
          </h5>
           <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="alert mt-2 mb-2 alert-dismissible alert-danger" v-if="errors.length > 0">
              <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
              <ul class="list-unstyled">
                <li v-for="e in errors" :key="e">{{e}}</li>
              </ul>
            </div>
            <div class="row mb-4">
              <label for="oldPassword-input" class="col-sm-3 col-form-label">Old Password</label>
              <div class="col-sm-9">
                <input type="password" class="form-control" id="oldPassword-input" v-model="oldPassword" :class="{ 'is-invalid': v$.oldPassword.$error }"/>
                <div class="invalid-feedback">
                  Please Enter Old Password
                </div> 
              </div>
            </div>
            <div class="row mb-4">
              <label for="newPassword-input" class="col-sm-3 col-form-label">New Password</label>
              <div class="col-sm-9">
                <input type="password" class="form-control" id="newPassword-input" v-model="newPassword" :class="{ 'is-invalid': v$.newPassword.$error }"/>
                <div class="invalid-feedback">
                  Please Enter New Password
                </div> 
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="close"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary"
            :disabled="processing" 
            @click="tryChange"
          >
           <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
