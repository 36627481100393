<script>
import Custom from "@/views/layouts/custom";
import appConfig from "@/app.config";
import AccountForm from "./account-form"
import UserForm from "./user-form"
import ChangePasswordForm from "./change-password-form"
import { mapGetters } from 'vuex';

export default {
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Custom,
    AccountForm,
    UserForm,
    ChangePasswordForm
  },
  data() {
    return {
      accountEditFormVisible: false,
      userFormVisible: false,
      changePasswordFormVisible: false,
      pagetitle: "Profile",
    };
  },
  computed :{
    ...mapGetters('auth', {
        currentUser: 'currentUser'
    }),
  },
};
</script>

<template>
  <custom :layout="'vertical'" :is-busy="isBusy" :title="pagetitle">
   <template v-slot:content>
      <div class="container-fluid">
      <AccountForm v-if="accountEditFormVisible" @close="accountEditFormVisible = false">
      </AccountForm>
      <UserForm v-if="userFormVisible" @close="userFormVisible = false">
      </UserForm>
      <ChangePasswordForm v-if="changePasswordFormVisible" @close="changePasswordFormVisible = false">
      </ChangePasswordForm>   

      <div class="row">
        <div class="col-xl-8 col-lg-12" v-if="currentUser ">
          <div class="mt-2 mb-2" >
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Account Information</h4>
              </div>
              <div class="card-body">
                <dl class="row">
                  <dt class="col-sm-3">Company Name</dt>
                  <dd class="col-sm-9">{{currentUser.account.name}}</dd>
                  <dt class="col-sm-3">Time Zone</dt>
                  <dd class="col-sm-9">{{currentUser.account.timeZone}}</dd>
                </dl>
                <div class="row justify-content-end" v-if="(currentUser || {}).isOwner">
                  <div class="col-sm-9">
                    <button type="button" 
                      class="btn btn-secondary w-md mb-2" 
                      @click="accountEditFormVisible = true"
                    > 
                      Change 
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Personal Information</h4>
              </div>
              <div class="card-body">
                <dl class="row">
                  <dt class="col-sm-3">Name</dt>
                  <dd class="col-sm-9">{{currentUser.name}}</dd>
                  <dt class="col-sm-3">Email</dt>
                  <dd class="col-sm-9">{{currentUser.email}}</dd>
                  <dt class="col-sm-3">Phone Number</dt>
                  <dd class="col-sm-9">
                    {{currentUser.phoneNumber}}
                    <em class="text-muted" v-if="!currentUser.phoneNumber"> 
                      &nbsp;[no data]
                    </em>
                  </dd>
                  <dt class="col-sm-3">Role</dt>
                  <dd class="col-sm-9">
                    <span class=" badge bg-dark me-2" v-if="currentUser.isOwner">Owner</span>
                    <span class="badge badge-soft-primary mb-0" v-if="currentUser.isAdmin">Admin</span>
                  </dd>
                </dl>
                <div class="row justify-content-end">
                  <div class="col-sm-9">
                    <button type="button " 
                      class="btn btn-secondary w-md me-4 mb-2" 
                      @click="userFormVisible = true"
                    > 
                      Change Personal Information
                    </button>
                    <button type="button" 
                      class="btn btn-secondary w-md mb-2" 
                      @click="changePasswordFormVisible = true"
                    > 
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-12">
          <div class="mt-2 mb-2">
            
          </div>
        </div>
      </div>
        <div class="row">
        <div class="col-xl-8 col-lg-12">
          <div class="mt-2 mb-2">
            
          </div>
        </div>
      </div>
    </div>
    </template>
  </custom>
</template>
