<script>
import { mapGetters, mapActions } from 'vuex';
import PhoneNumber from "@/components/phone-number"
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components:{
    PhoneNumber
  },
  data() {
    return {
      email: null,
      name: null,
      phoneNumber: null
    };
  },
  created() {
    if(this.currentUser != null) {
      this.email = this.currentUser.email;
      this.phoneNumber = this.currentUser.phoneNumber;
      this.name = this.currentUser.name;
    }
  },
  validations: {
    name: { required }
  },
  computed :{
    ...mapGetters('auth', {
        currentUser: 'currentUser'
    }),
    ...mapGetters('account', {
        processing: 'profileUpdating'
    }),
  },
  mounted(){
    var modal = new Modal(this.$refs.modal, {keyboard : false, backdrop: 'statis', focus: true});
    modal.show();
     pushModal({modalInstance: modal, forceClose: this.close });
  },
  methods: {
    ...mapActions('account', {
        update: 'updateProfile'
    }),
    async close(){
      var modal = Modal.getInstance(this.$refs.modal);
      modal.hide();
      popModal();
      this.$emit("close");
    },
    async tryUpdate(){
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.update({name: this.name, phoneNumber: this.phoneNumber }).then(()=>{
          this.$toast.open({message: 'Data was saved successfully.'});
          this.close();
        });
      }
    }
  }
};
</script>

<template>
 <div
    class="modal fade"
    id="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit User Information
          </h5>
           <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row mb-4">
                <label for="email-input" class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" id="email-input" 
                    :value="email" 
                    readonly="readonly"
                  />
                </div>
              </div>
              <div class="row mb-4">
                <label for="name-input" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                  <input type="text" 
                    class="form-control" 
                    placeholder="Enter Name" 
                    id="name-input"  
                    v-model="name" 
                    :class="{ 'is-invalid': v$.name.$error }"
                  />
                  <div class="invalid-feedback">
                    Please Enter Name
                  </div> 
                </div>
              </div>
              <div class="row mb-4">
                <label for="phone-input" class="col-sm-3 col-form-label">Phone Number</label>
                <div class="col-sm-9">
                
                  <phone-number :value="phoneNumber" @changed="(v) => phoneNumber = v" placeholder="Enter Phone Number">

                  </phone-number>
                </div>
              </div>
            </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="close"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary"
            :disabled="processing" 
            @click="tryUpdate"
          >
           <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
