<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core'
import { Modal } from 'bootstrap'
import { popModal, pushModal } from "@/modals";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      name: null,
      timeZone: null,
      timeZones: []
    };
  },
  created(){
    this.timeZones = moment.tz.names();
    this.name = this.currentUser.account.name;
    this.timeZone = this.currentUser.account.timeZone;
  },
  
  validations: {
    name: { required },
    timeZone: { required }
  },
  mounted(){
    var modal = new Modal(this.$refs.accountEditModal, {keyboard : false, backdrop: 'statis', focus: true});
    modal.show();
    pushModal({modalInstance: modal, forceClose: this.close });
  },
  computed :{
    ...mapGetters('auth', {
        currentUser: 'currentUser'
    }),
    ...mapGetters('account', {
        processing: 'accountUpdating'
    }),
  },
  methods: {
    ...mapActions('account', {
        update: 'updateAccount'
    }),
    async close(){
      var modal = Modal.getInstance(this.$refs.accountEditModal);
      modal.hide();
      popModal();
      this.$emit("close");
    },
    async tryUpdate() {
      
      const isValid = await this.v$.$validate();
      if(isValid) {
        this.update({name: this.name, timeZone: this.timeZone, id: this.currentUser.account.id}).then(()=>{
          this.$toast.open({message: 'Data was saved successfully.'});
          this.close();
        });
      }
    }
  }
};
</script>

<template>
  <div
    class="modal fade"
    ref="accountEditModal"
  >
    <div class="modal-dialog  modal-dialog-scrollable modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit Account Information
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row mb-4">
              <label for="company-name-input" class="col-sm-3 col-form-label">Company Name</label>
              <div class="col-sm-9">
                <input 
                  type="text" 
                  class="form-control" 
                  placeholder="Enter Company Name" 
                  id="company-name-input" 
                  :class="{ 'is-invalid': v$.name.$error }"
                  v-model="name"
                />
                <div class="invalid-feedback">
                  Please Enter Company Name
                </div> 
              </div>
            </div>
            <div class="row mb-4">
              <label for="input-timeZone" class="col-sm-3 col-form-label">Time Zone</label>
              <div class="col-sm-9">
                <select 
                  id="input-timeZone"
                  class="form-control"
                  v-model="timeZone"
                  :class="{ 'is-invalid': v$.timeZone.$error }"
                >
                  <option :value="null">Please Select Time Zone</option>
                  <option v-for="tz in timeZones" :value="tz" :key="tz">{{tz}}</option>
                </select>
                <div class="invalid-feedback">
                  Please Select Time Zone
                </div> 
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="close"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary"
            :disabled="processing" 
            @click="tryUpdate"
          >
           <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
